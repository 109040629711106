import isLoading from "./spinnerFunction";

const fetchData = async (url) => {
    try {
        isLoading(true);
        const response = await fetch(url);
        return await response.json();
    } catch (error) {
        console.log('error: ', error);
    } finally {
        setTimeout(() => {
            isLoading(false);
        }, 500);
    }
};

const postData = async (url, data) => {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    console.log('csrfToken: ', csrfToken); 
    try {
        isLoading(true);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
            },
            body: JSON.stringify(data),
        });

        console.log('response postData(): ', response);

        return await response.json();
    } catch (error) {
        console.error('Error al realizar la solicitud POST:', error);
    } finally {
        setTimeout(() => {
            isLoading(false);
        }, 500);
    }
};


const performPostRequest = async (url, data) => {
    try {
        const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
        isLoading(true);
        
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken,
            },
            body: formData,
        });

        return response;
        
    } catch (error) {
        console.error('Error al realizar la solicitud POST:', error);
    } finally {
        setTimeout(() => {
            isLoading(false);
        }, 500);
    }
};


export { fetchData, postData, performPostRequest };