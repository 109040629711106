import validationLoginForm from './functions/validationLoginForm.js';
import profileItemsContainer from './functions/usernameToggler.js';
import editProfileBtn from './functions/editProfileBtn.js';
import mobileBtn from './functions/mobileBtn.js';
import dotsBtn from './functions/dotsBtn.js';
import subMenuBtn from './functions/subMenuBtn.js';
import validationCrudsForms from './functions/validationCrudsForms.js';
import validationsProfile from './functions/validationsProfile.js';
import filterAforo from './functions/filterAforo.js';
import filterBooking from './functions/filterBooking.js';
import selectAdmin from './functions/selectAdmin.js';
import bookingDetails from './functions/bookingDetails.js';
import filterBookingVerify from './functions/filterBookingVerify.js';
import bookingVerifyDetailsService from './functions/bookingVerifyDetailsService.js';
import cardFunctions from './functions/cardFunctions.js';
import validationsFilters from './functions/validationsFilters.js';
import notificationFunctions from './functions/notificationFunctions.js';
import progressBar from './functions/progressBar.js';
import modifyBooking from './functions/modifyBooking.js';
import bookingVerify from './functions/bookingVerify.js';
import dashboardGraphics from './functions/dashboardGraphics.js';
import dashboardFunctions from './functions/dashboardFunctions.js';
import bookingInstallFunctions from './functions/bookingInstallFunctions.js';
import aforosFunctions from './functions/aforosFunctions.js';
import stepOnePreselectionFunctions from './functions/stepOnePreselectionFunctions.js';
import bookingPreselectionValidation from './functions/bookingPreselectionValidation.js';
import aforosPreselectedBoxFunctions from './functions/aforosPreselectedBoxFunctions.js';
import clienteAnuncianteFunctions from './functions/clienteAnuncianteFunctions.js';
import formatCurrencyCop from './functions/helpers/formatCurrencyCop.js';

document.addEventListener('DOMContentLoaded', () => {

 
  

  const crudsFormFunctions = validationCrudsForms();
  const dashboardGraphicsFunctions = dashboardGraphics();

  dashboardFunctions();
  aforosFunctions();
  validationLoginForm();
  profileItemsContainer();
  editProfileBtn();
  mobileBtn();
  dotsBtn();
  subMenuBtn();
  validationsProfile();
  stepOnePreselectionFunctions();
  progressBar();
  bookingPreselectionValidation();
  //aforosPreselectedBoxFunctions();
  clienteAnuncianteFunctions();
  formatCurrencyCop();
  
  crudsFormFunctions.centralAgencyCreateFunction();
  crudsFormFunctions.centralAgencyEditFunction();
  crudsFormFunctions.deleteFunction();
  crudsFormFunctions.contactAgencyCreateFunction();
  crudsFormFunctions.contactAgencyEditFunction();
  crudsFormFunctions.companyCreate();
  crudsFormFunctions.companyEdit();
  crudsFormFunctions.campaingCreate();
  crudsFormFunctions.campaingEdit();

  filterAforo();
  filterBooking();
  filterBookingVerify();
  selectAdmin();
  bookingDetails();
  bookingInstallFunctions();

  crudsFormFunctions.bookingCreate();

  bookingVerifyDetailsService();
  crudsFormFunctions.validationBookingDate();
  // cardFunctions();

  validationsFilters();
  notificationFunctions();
  modifyBooking();
  bookingVerify();
  dashboardGraphicsFunctions.chartOccupation();
  dashboardGraphicsFunctions.chartElements();
  dashboardGraphicsFunctions.chartTopCommercial();
  dashboardGraphicsFunctions.chartCampaigns();
  

});